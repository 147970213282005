<template>
  <v-container fluid v-if="!loading">
    <v-card rounded width="100%" v-if="processos.length">
      <v-card-title class="justify-center mb-3 titulo">
        <h2>
          Lançamento Administrativo
        </h2>
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-row justify="center">
            <v-col cols="12" sm="6">
              <EscolhaProcessoAutocomplete
                  :disabled="tab === 1"
                  :processos="processos"
                  v-model="processoSelecionado"
              />
            </v-col>
          </v-row>
          <v-divider class="my-5"/>
          <v-window v-model="tab">
            <v-window-item>
              <v-card
                  v-if="
                  opcoes.length > 0 &&
                    Object.keys(processoSelecionado).length > 0
                "
              >
                <v-card-title>
                  Opções
                  <v-spacer/>
                  <v-text-field
                      v-model="buscar"
                      append-icon="mdi-magnify"
                      label="Buscar"
                      single-line
                      hide-details
                      name="buscarOpcao"
                      dense
                  ></v-text-field>
                </v-card-title>
                <v-card-text>
                  <v-data-table
                      disable-pagination
                      hide-default-footer
                      :headers="headers"
                      :items="opcoes"
                      :search="buscar"
                      @click:row="carregarOpcao"
                      class="elevation-1"
                  >
                    <template v-slot:item.situacoesLancadas="{ item }">
                      <v-container>
                        <v-row justify="center">
                          <v-icon
                              v-if="item.situacoesLancadas"
                              color="primary"
                              class="mr-1"
                              size="21"
                          >mdi-check-circle-outline
                          </v-icon>
                          <v-icon v-else color="amber" class="mr-1" size="21"
                          >mdi-alert-circle-outline
                          </v-icon>
                          {{ item.situacoesLancadas ? "Ok" : "Pendente" }}
                        </v-row>
                      </v-container>
                    </template>
                    <template v-slot:item.documentosAvaliados="{ item }">
                      <v-container>
                        <v-row justify="center">
                          <v-icon
                              v-if="item.documentosAvaliados"
                              color="primary"
                              class="mr-1"
                              size="21"
                          >mdi-check-circle-outline
                          </v-icon>
                          <v-icon v-else color="amber" class="mr-1" size="21">
                            mdi-alert-circle-outline
                          </v-icon>
                          {{ item.documentosAvaliados ? "Ok" : "Pendente" }}
                        </v-row>
                      </v-container>
                    </template>
                    <template v-slot:item.parecerLancado="{ item }">
                      <v-container>
                        <v-row v-if="!pareceresLancados && !processoSelecionado.lancamentoParecer" justify="center">
                          <span><b>-</b></span>
                        </v-row>
                        <v-row v-else justify="center">
                          <v-icon
                              v-if="item.parecerLancado"
                              color="primary"
                              class="mr-1"
                              size="21"
                          >mdi-check-circle-outline
                          </v-icon>
                          <v-icon v-else color="amber" class="mr-1" size="21">
                            mdi-alert-circle-outline
                          </v-icon>
                          {{ item.parecerLancado ? "Ok" : "Pendente" }}
                        </v-row>
                      </v-container>
                    </template>
                    <template v-slot:item.notasLancadas="{ item }">
                      <v-container>
                        <v-row v-if="!processoSelecionado.possuiTipoNota" justify="center">
                          <span><b>-</b></span>
                        </v-row>
                        <v-row v-else justify="center">
                          <v-icon
                              v-if="item.notasLancadas"
                              color="primary"
                              class="mr-1"
                              size="21"
                          >mdi-check-circle-outline
                          </v-icon>
                          <v-icon v-else color="amber" class="mr-1" size="21">
                            mdi-alert-circle-outline
                          </v-icon>
                          {{ item.notasLancadas ? "Ok" : "Pendente" }}
                        </v-row>
                      </v-container>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-window-item>
            <v-window-item>
              <LancamentoAdministrativo
                  v-if="tab === 1"
                  @voltar="voltarTab"
                  @lancamentoRealizado="atualizarOpcoes"
                  :opcao="opcaoSelecionada"
                  :processo="processoSelecionado"
              />
            </v-window-item>
          </v-window>
        </v-container>
      </v-card-text>
    </v-card>
    <v-row v-else align="center" class="mt-10" justify="center">
      <v-alert type="info" prominent colored-border border="left" elevation="1">
        Não existem processos para lançamento de resultados
      </v-alert>
    </v-row>
    <v-row v-if="processoSelecionado.oid && !opcoes.length && !loadingOpcoes" align="center" class="mt-10" justify="center">
      <v-alert type="info" prominent colored-border border="left" elevation="1">
        Não existem opções para lançamento de resultados
      </v-alert>
    </v-row>
  </v-container>
</template>

<script>
import EscolhaProcessoAutocomplete from "@/components/base/EscolhaProcessoAutocomplete";
import lancamentoService from "@/services/lancamento.service";
import LancamentoAdministrativo from "./LancamentoAdministrativo.vue";

export default {
  name: "FiltroLancamentoAdministrativo",

  components: {LancamentoAdministrativo, EscolhaProcessoAutocomplete},

  async created() {
    await lancamentoService
        .recuperarTodosProcessosLancamento()
        .then(response => {
          this.processos = response.data;
          this.loading = false;
        })
        .catch(() => {
        });
  },

  watch: {
    processoSelecionado: {
      async handler() {
        if (this.processoSelecionado.oid) {
          const loader = this.$loading.show();
          this.loadingOpcoes = true;
          await lancamentoService
              .recuperarOpcoesPorProcesso(this.processoSelecionado.oid)
              .then(response => {
                this.opcoes = response.data;
                this.loadingOpcoes = false;
              });
          loader.hide();
        } else {
          this.opcoes = [];
          this.loadingOpcoes = false;
        }
      }
    }
  },

  methods: {
    async atualizarOpcoes() {
      await lancamentoService
          .recuperarOpcoesPorProcesso(this.processoSelecionado.oid)
          .then(response => {
            this.opcoes = response.data;
          });
    },

    async carregarOpcao(opcao) {
      this.opcaoSelecionada = opcao;
      this.tab = 1;
    },

    voltarTab() {
      this.tab = 0;
    }
  },

  data() {
    return {
      loading: true,
      loadingOpcoes: true,
      processoSelecionado: {},
      processos: [],
      opcaoSelecionada: {},
      opcoes: [],
      tab: 0,
      buscar: ""
    };
  },

  computed: {
    headers() {
      let header = [
        {text: "Nome", value: "nome"},
        {text: "Situações Lançadas", value: "situacoesLancadas", align: "center"}
      ]

      if (this.processoSelecionado.possuiRecurso) {
        header.push({text: "Documentos Avaliados", value: "documentosAvaliados", align: "center"},
            {text: "Pareceres Lançados", value: "parecerLancado", align: "center"});

      }

      if (this.processoSelecionado.lancamentoNota) {
        header.push({text: "Notas Lançadas", value: "notasLancadas", align: "center"});
      }

      if (this.processoSelecionado.lancamentoProeficiencia) {
        header = [];
        header.push({text: "Nome", value: "nome"});
      }

      return header;
    },

    pareceresLancados() {
      if (this.opcoes.length > 0) {
        return this.opcoes.filter(o => o.parecerLancado).length > 0;
      }
      return false;
    }
  }
};
</script>

<style>
.v-data-table__wrapper {
  cursor: pointer;
}
</style>
