<template>
  <v-card>
    <v-container class="mb-5">
      <v-btn small outlined @click="retornarParaFiltro" color="accent">
        <v-icon left>mdi-arrow-left-bold</v-icon>
        Voltar
      </v-btn>
      <v-row style="align-items: center" justify="center">
        <v-card-title class="justify-center titulo">
          <h3>{{ this.opcao.nome }}</h3>

        </v-card-title>
      </v-row>
      <v-row justify="end">
        <v-btn v-if="processo.possuiBonus && isAdministrador()  " @click="mudarTab" text small color="info">
          <v-icon left
          >{{ tab === 0 ? "mdi-star-plus" : "mdi-check-all" }}
          </v-icon>
          {{ textoBotaoBonus }}
        </v-btn>
      </v-row>
    </v-container>
    <v-window v-model="tab">
      <v-window-item>
        <v-card>
          <v-form ref="lancamentoForm">
            <v-card-text>
              <v-data-table
                  v-if="inscricoes.length > 0"
                  :headers="headersInscricao"
                  @page-count="pageCount = $event"
                  :page.sync="page"
                  :items-per-page="25"
                  :footer-props="{
                  showFirstLastPage: true,
                  showCurrentPage: true,
                  itemsPerPageOptions: [25, 50, 100]
                }"
                  :expanded.sync="expanded"
                  :show-expand="processo.possuiRecurso"
                  item-key="numeroInscricao"
                  :items="inscricoes"
                  disable-sort
                  class="elevation-1"
                  @click:row="handleClickRow"
              >
                <template v-slot:item.situacaoInscricaoCodigo="{ item }">
                  <v-select
                      hide-details
                      :disabled="item.situacaoInscricaoCodigo === 'ELMC' ? true : item.situacaoInscricaoCodigo === 'ELM' ? isAvaliador() : false"
                      :items="situacoes"
                      dense
                      outlined
                      v-model="item.situacaoInscricaoCodigo"
                      v-on:change="handleOnChange(item); item.situacaoInscricaoCodigo === 'FALP' ? item.notaEspecifica = '' : ''"
                      @click.stop
                  >
                    <template v-slot:prepend-inner>
                      <v-tooltip
                          v-if="processo.possuiRecurso && situacoesAvaliacaoDocumentos.includes(item.situacaoInscricaoCodigo)"
                          top :open-delay="defaultDelay">
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">
                            <v-icon v-if="deveAvaliar(item)" color="error">
                              mdi-alert-circle-outline
                            </v-icon>
                            <v-icon v-else>
                              {{ avaliacaoOuParecerPreenchidos(item) ? 'mdi-text-long' : '' }}
                            </v-icon>
                          </span>
                        </template>
                        <span>
                          {{
                            deveAvaliar(item) ? processo.lancamentoSituacao ? 'Avaliação documentação não preenchida' : 'Parecer não preenchido'
                                : processo.lancamentoSituacao ? 'Avaliação documentação preenchida' : 'Parecer preenchido'
                          }}
                        </span>
                      </v-tooltip>
                    </template>
                  </v-select>
                </template>
                <template v-slot:item.notaTeorica="{ item }">
                  <v-auto-numeric
                      hide-details
                      dense
                      outlined
                      :color="!!item.notaTeorica ? 'primary' : 'error'"
                      :background-color="
                      !!item.notaTeorica ? 'default' : '#ffebe6'
                    "
                      v-model="item.notaTeorica"
                  />
                </template>
                <template v-slot:item.notaPratica="{ item }">
                  <v-auto-numeric
                      hide-details
                      dense
                      outlined
                      :color="!!item.notaPratica ? 'primary' : 'error'"
                      :background-color="
                      !!item.notaPratica ? 'default' : '#ffebe6'
                    "
                      v-model="item.notaPratica"
                  />
                </template>
                <template v-slot:item.notaEspecifica="{ item }">
                  <v-auto-numeric
                      :disabled="item.situacaoInscricaoCodigo === 'FALP'"
                      hide-details
                      dense
                      outlined
                      :color="!!item.notaEspecifica ? 'primary' : 'error'"
                      :background-color="
                      item.situacaoInscricaoCodigo === 'FALP' ? '#efedec' :
                      !!item.notaEspecifica ? 'default' : '#ffebe6'
                    "
                      v-model="item.notaEspecifica"
                  />
                </template>
                <template v-slot:item.notaRedacao="{ item }">
                  <v-auto-numeric
                      :disabled="item.situacaoInscricaoCodigo === 'FALP'"
                      hide-details
                      dense
                      outlined
                      :color="!!item.notaRedacao ? 'primary' : 'error'"
                      :background-color="
                      item.situacaoInscricaoCodigo === 'FALP' ? '#efedec' :
                      !!item.notaEspecifica ? 'default' : '#ffebe6'
                    "
                      v-model="item.notaRedacao"
                  />
                </template>
                <template v-slot:item.instrumentos="{ item }">
                  <div v-if="item.instrumentos">
                    <span :key="index" v-for="(instrumento, index) in item.instrumentos">
                      {{instrumento}}
                      <br/>
                    </span>
                  </div>
                  <span v-else>-</span>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-tooltip top :open-delay="defaultDelay">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          v-bind="attrs"
                          v-on="on"
                          class="justify-center"
                          icon
                          color="accent"
                          @click.stop="downloadDocumentosParaVisualizacao(item)"
                      >
                        <v-icon>
                          mdi-file-eye
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Visualizar documentos</span>
                  </v-tooltip>
                  <v-tooltip top :open-delay="defaultDelay">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          v-bind="attrs"
                          v-on="on"
                          class="justify-center"
                          icon
                          color="info"
                          @click.stop="downloadDocumentos(item)"
                      >
                        <v-icon>
                          mdi-download
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Baixar documentos</span>
                  </v-tooltip>
                </template>
                <template
                    v-if="processo.possuiRecurso"
                    v-slot:item.data-table-expand="{ expand, isExpanded, item }"
                >
                  <v-tooltip
                      v-if="podeEditarAvaliacao(item.situacaoInscricaoCodigo) && (situacoesAvaliacaoDocumentos.includes(item.situacaoInscricaoCodigo) || processo.lancamentoParecer)"
                      top
                      :open-delay="defaultDelay"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                          v-bind="attrs"
                          v-on="on"
                          @click.stop="expand(!isExpanded)"
                          :disabled="deveAvaliar(item)"
                      >
                        {{ isExpanded ? "mdi-chevron-up" : "mdi-chevron-down" }}
                      </v-icon>
                    </template>
                    <span>{{ isExpanded ? "Ocultar avaliação documentação" : "Exibir avaliação documentação" }}</span>
                  </v-tooltip>
                </template>
                <template
                    v-if="processo.possuiRecurso"
                    v-slot:expanded-item="{ headers, item }"
                >
                  <td :colspan="headers.length">
                    <v-sheet class="my-3" outlined>
                      <v-tabs
                          v-model="item.tabRecurso"
                          show-arrows
                          centered
                          color="primary"
                      >
                        <v-tab>
                          Avaliação Documentação
                          <v-icon right color="error" v-if="processo.lancamentoSituacao && deveAvaliar(item)">
                            mdi-alert-circle-outline
                          </v-icon>
                        </v-tab>
                        <v-tab v-if="processo.lancamentoParecer">
                          Recurso Candidato
                        </v-tab>
                        <v-tab v-if="processo.lancamentoParecer">
                          Parecer Avaliador
                          <v-icon right color="error" v-if="deveAvaliar(item)">mdi-alert-circle-outline</v-icon>
                        </v-tab>
                        <v-tabs-items v-model="item.tabRecurso" touchless>
                          <v-tab-item>
                            <v-card flat>
                              <v-card-text>
                                <div
                                    v-if="processo.lancamentoSituacao && podeEditarAvaliacao(item.situacaoInscricaoCodigo)">
                                  <v-textarea
                                      :rules="[tamanhoMaximoRule(2000), campoAvaliacaoObrigatoriaRule(item.avaliacao, item.situacaoInscricaoCodigo)]"
                                      :counter="2000"
                                      outlined
                                      v-model="item.avaliacao"
                                  >
                                  </v-textarea>
                                </div>
                                <div v-else>
                                  <v-sheet class="pa-2 mb-2" outlined>
                                    <p
                                        style="white-space: pre-line"
                                        class="text-justify"
                                    >
                                      {{ item.avaliacao }}
                                    </p>
                                  </v-sheet>
                                </div>
                              </v-card-text>
                            </v-card>
                          </v-tab-item>
                          <v-tab-item>
                            <v-card flat>
                              <v-card-text class="mx-2">
                                <v-sheet class="pa-2 mb-2" outlined>
                                  <p
                                      style="white-space: pre-line"
                                      class="text-justify"
                                  >
                                    {{ item.justificativaCandidato }}
                                  </p>
                                </v-sheet>
                              </v-card-text>
                            </v-card>
                          </v-tab-item>
                          <v-tab-item>
                            <v-card flat>
                              <v-card-text>
                                <div
                                    v-if="processo.lancamentoParecer && podeEditarAvaliacao(item.situacaoInscricaoCodigo)">
                                  <v-textarea
                                      :rules="[tamanhoMaximoRule(2000), campoAvaliacaoObrigatoriaRule(item.parecerAvaliador, item.situacaoInscricaoCodigo)]"
                                      :counter="2000"
                                      outlined
                                      v-model="item.parecerAvaliador"
                                  >
                                  </v-textarea>
                                </div>
                                <div v-else>
                                  <v-sheet class="pa-2" outlined>
                                    <p
                                        style="white-space: pre-line"
                                        class="text-justify"
                                    >
                                      {{ item.parecerAvaliador }}
                                    </p>
                                  </v-sheet>
                                </div>
                              </v-card-text>
                            </v-card>
                          </v-tab-item>
                        </v-tabs-items>
                      </v-tabs>
                      <v-row class="mb-4 align-center justify-center">
                        <v-btn :disabled="deveAvaliar(item)"
                               class="align-center justify-center"
                               color="accent"
                               @click="clickColumn(item)"
                               outlined
                        >
                          <v-icon left>
                            mdi-eye-off
                          </v-icon>
                          Ocultar Avaliação Documentação
                        </v-btn>
                      </v-row>
                    </v-sheet>
                    <v-sheet/>
                  </td>
                </template>

                <template v-slot:footer.prepend>
                  <v-spacer></v-spacer>
                  <span class="mr-6">Página:</span>
                  <div class="text-center pt-2">
                    <v-sheet max-width="80" class="mr-6">
                      <v-select
                          style="font-size: 0.75rem"
                          :items="
                          Array.from({ length: pageCount }, (v, k) => k + 1)
                        "
                          v-model="page"
                      >
                      </v-select>
                    </v-sheet>
                  </div>
                </template>
              </v-data-table>
              <v-row v-else-if="loading" align="center" justify="center">
                <v-alert type="info" border="left" colored-border outlined>
                  Não existem inscrições para lançamento na opção selecionada.
                </v-alert>
              </v-row>
            </v-card-text>
          </v-form>
        </v-card>
      </v-window-item>

      <v-window-item v-if="tab === 1">
        <v-card-text>
          <v-data-table
              v-if="inscricoesBonus.length > 0"
              :headers="headersBonus"
              :items-per-page="25"
              :footer-props="{
            showFirstLastPage: true,
            showCurrentPage: true,
            itemsPerPageOptions: [5, 10, 25, 50, 100]
          }"
              :items="inscricoesBonus"
              class="elevation-1"
          >
            <template v-slot:item.bonus="{ item }">
              <v-select
                  hide-details
                  :items="[
                { value: 1, text: '0%' },
                { value: 1.15, text: '15%' },
                { value: 1.3, text: '30%' }
              ]"
                  dense
                  outlined
                  v-model="item.bonus"
              />
            </template>
          </v-data-table>
          <v-row class="mt-2" v-else-if="loading" align="center" justify="center">
            <v-alert type="info" border="left" colored-border outlined>
              Não existem inscrições para lançamento de bônus na opção selecionada.
            </v-alert>
          </v-row>
        </v-card-text>
      </v-window-item>
    </v-window>

    <v-dialog scrollable v-if="dialogDocuments" v-model="dialogDocuments" width="80%">
      <v-card>
        <v-card-title class="primary justify-center white--text">
          Documentos
          <v-spacer></v-spacer>
          <v-btn icon color="white" @click="dialogDocuments = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-tabs v-model="documentosTab" show-arrows>
          <v-tab v-for="arquivo in arquivosVisualizacao" :key="arquivo.nome">
            <span class="d-inline-block text-truncate"> {{ arquivo.nome }} </span>
            <v-chip v-if="!!arquivo.faseEnvio"
                    text-color="white"
                    color="info"
                    class="ml-1"
                    small>
              <span class="text-truncate">
                  {{ arquivo.faseEnvio }}
              </span>
            </v-chip>
          </v-tab>
        </v-tabs>
        <v-card-text style="overflow: hidden">
          <v-tabs-items v-model="documentosTab">
            <v-tab-item
                v-for="arquivo in arquivosVisualizacao"
                :key="arquivo.nome"
            >
              <DocumentsViewer :file="arquivo"/>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-card-actions>
      <v-container>
        <v-row class="float-right">
          <!--<v-btn class="mx-4" v-if="processo.tipoProcessoCodigo === 'TEE' && processo.lancamentoSituacao"
                  @click="alterarNaoAvaliados" color="primary">
            <v-icon left>
              mdi-swap-horizontal-bold
            </v-icon>
            Marcar todos "A lançar" como "Não Avaliados"
          </v-btn>-->
          <v-btn color="primary" @click="tab === 0 ? lancarResultado() : lancarBonus()">
            <v-icon left>mdi-content-save</v-icon>
            Salvar
          </v-btn>
        </v-row>
      </v-container>
    </v-card-actions>
  </v-card>
</template>

<script>
import lancamentoService from "@/services/lancamento.service";
import documentosService from "@/services/documentos.service";
import rules from "@/commons/rules";
import {ProcessosTIM} from "@/commons/constants";
import {mapActions, mapGetters} from "vuex";
import DocumentsViewer from "@/components/base/DocumentsViewer";

export default {
  name: "LancamentoAdministrativo",
  components: {DocumentsViewer},
  props: {
    processo: {type: Object, required: true},
    opcao: {type: Object, required: true}
  },

  data() {
    return {
      tab: 0,
      page: 1,
      pageCount: 0,
      loading: 0,
      defaultDelay: 1000,
      expanded: [],
      headersBonus: [
        {text: "Nome do Candidato", value: "nomeCandidato"},
        {text: "Número de Inscrição", value: "numeroInscricao"},
        {text: "Curso de Origem", value: "nomeCursoOrigem"},
        {text: "Curso da Opção", value: "nomeOpcao"},
        {text: "Bônus", value: "bonus"}
      ],
      inscricoes: [],
      inscricoesIniciais: [],
      inscricoesBonus: [],
      inscricoesIniciaisBonus: [],
      contaSortNotaPratica: 0,
      arquivosVisualizacao: [],
      dialogDocuments: false,
      inscricaoSelecionada: {},
      documentosTab: 0,
      headerExportTable: [
        {text: "Nome do Candidato", value: "nome"},
        {text: "CPF", value: "cpf"},
        {text: "Numero Inscrição", value: "numeroInscricao"},
      ],
      situacoesAvaliacaoDocumentos: ['NAP', 'NAV', 'ELM', 'FALD'],
      situacoes: [
        { value: 'ATV', text: 'A Lançar' },
        { value: 'APT', text: 'Apto' },
        { value: 'NAP', text: 'Não Apto (Situação)'},
        { value: 'NAPP', text: 'Não Apto (Prova)'},
        {value: 'FALP', text: 'Faltoso Prova'},
        {value: 'NAV', text: 'Não Avaliado'},
        {value: 'NPR', text: 'Não Proeficiente'},
        {value: 'FALE', text: 'Faltou Etapa'},
        {value: 'ELM', text: 'Eliminado'},
        {value: 'ELMD', text: 'Eliminado Documentação'},
        {value: 'ELMC', text: 'Não Classificado'},


      ]

    };
  },

  async created() {
    const opcaoOid = this.opcao.oid;
    const loader = this.$loading.show();

    await lancamentoService
        .recuperarInscricoesLancamentoPorOpcao(opcaoOid)
        .then(response => {
          if (this.processo.lancamentoParecer) {
            this.inscricoes = this.filtrarInscricoesPediuRecurso(response.data);
          } else {
            this.inscricoes = this.filtrarInscricoesLancamentoNota(response.data);
          }
          this.inscricoesBonus = this.filtrarInscricoesBonus(response.data);
        })
        .catch(() => {
          this.exibirAviso({
            mensagem: "Não foi possivel recuperar inscrições",
            tipo: "error"
          });
          loader.hide();
        });

    this.inscricoesIniciaisBonus = this.inscricoesBonus.map(inscricao => {
      return {...inscricao};
    });

    this.inscricoesIniciais = this.inscricoes.map(inscricao => {
      return {...inscricao};
    });

    this.loading = true;
    loader.hide();
  },

  computed: {
    textoBotaoBonus() {
      if (this.tab === 0) {
        return "Lançar Bonus";
      }
      if (this.tab === 1) {
        return "Lançar Resultados";
      }
      return "Lançar";
    },



    headersInscricao() {
      let headersInscricao = [
        {text: "Nome do Candidato", value: "nomeCandidato"},
        {text: "Número de Inscrição", value: "numeroInscricao"},
      ];


      if (this.processo.tipoProcessoCodigo === 'THEM')
      {
        headersInscricao.push({text: "Instrumento(s)", value: "instrumentos"})
      }

      if (this.processo.lancamentoNota) {
        if (ProcessosTIM.includes(this.processo.tipoProcessoCodigo)) {
          headersInscricao.push({text: "Presença", value: "situacaoInscricaoCodigo"})
        } else {
          headersInscricao.push({text: "Situação Inscrição", value: "situacaoInscricaoCodigo"})
        }

        if (this.processo.possuiNotaPratica) {
          headersInscricao.push({
            text: "Nota Prática",
            value: "notaPratica"
          });
        }

        if (this.processo.possuiNotaTeorica) {
          headersInscricao.push({
            text: "Nota Teórica",
            value: "notaTeorica"
          });
        }

        if (this.processo.possuiNotaEspecifica) {
          headersInscricao.push({
            text: "Nota Específica",
            value: "notaEspecifica"
          });
        }

        if (this.processo.possuiNotaRedacao) {
          headersInscricao.push({
            text: "Nota Redação",
            value: "notaRedacao"
          });
        }
      } else {
        headersInscricao.push({text: "Situação Inscrição", value: "situacaoInscricaoCodigo"})
      }

      if (this.processo.possuiEnvioDocumentos) {
        headersInscricao.push({
          text: "Documentos",
          value: "actions",
          align: "center"
        });

        headersInscricao.push({
          text: "",
          value: "data-table-expand"
        });
      }
      return headersInscricao;
    }
  },

  methods: {
    ...rules,
    ...mapActions("avisos", ["exibirAviso"]),
    ...mapGetters("autenticacao", ["isAdministrador", "isAvaliador"]),

    async retornarParaFiltro() {
      this.$emit("voltar");
    },

    possuiNota(inscricao) {
      if (inscricao.notaEspecifica || inscricao.notaTeorica ||
          inscricao.notaPratica || inscricao.notaRedacao) {
        return true;
      }

      return false;
    },

    async lancarBonus() {
      const loader = this.$loading.show();
      const inscricoesAlteradas = this.verificaAlteracaoInscricoesBonus();

      if (inscricoesAlteradas.length) {
        await lancamentoService
            .lancarBonusService(inscricoesAlteradas)
            .then(async response => {
              if (response.status === 200) {
                this.inscricoesIniciaisBonus = this.inscricoesBonus.map(inscricao => {
                  return {...inscricao};
                });
                this.exibirAviso({
                  mensagem: "Lançamento de bônus realizado com sucesso!",
                  tipo: "success"
                });
                loader.hide();
                this.expanded = [];
                this.$emit("lancamentoRealizado");
              } else {
                this.exibirAviso({
                  mensagem: "Falha ao realizar lançamento de bônus!",
                  tipo: "error"
                });
              }
              loader.hide();
            })
            .catch(() => {
              this.exibirAviso({
                mensagem: "Falha ao realizar lançamento!",
                tipo: "error"
              });
              loader.hide();
            });
      } else {
        this.exibirAviso({
          mensagem: "Não há alteração a ser lançada",
          tipo: "warning"
        });
        loader.hide();
      }
    },


    async lancarResultado() {
      if (this.$refs.lancamentoForm.validate()) {
        const loader = this.$loading.show();
        const inscricoesAlteradas = this.verificaAlteracaoInscricoes();

        if (this.inscricaoComAvaliacaoDeDocumentosPendente()) {
          loader.hide();
          let calendario = this.processo.lancamentoParecer ? "parecer do recurso" : "avaliação de documentação";

          this.exibirAviso({
            mensagem: "Existem uma ou mais inscrições com situação 'Não Apto' e " + calendario + " pendente",
            tipo: "error"
          });
          return;
        }

        if (inscricoesAlteradas.length) {
          await lancamentoService
              .lancarResultado(inscricoesAlteradas)
              .then(async response => {
                if (response.status === 200) {
                  this.inscricoesIniciais = this.inscricoes.map(inscricao => {
                    return {...inscricao};
                  });
                  this.exibirAviso({
                    mensagem: "Lançamento realizado com sucesso!",
                    tipo: "success"
                  });
                  this.expanded = [];
                  this.$emit("lancamentoRealizado");
                } else {
                  this.exibirAviso({
                    mensagem: "Falha ao realizar lançamento!",
                    tipo: "error"
                  });
                }
                loader.hide();
              })
              .catch(() => {
                this.exibirAviso({
                  mensagem: "Falha ao realizar lançamento!",
                  tipo: "error"
                });
                loader.hide();
              });
        } else {
          this.exibirAviso({
            mensagem: "Não há alteração a ser lançada",
            tipo: "warning"
          });
          loader.hide();
        }
      } else {
        this.exibirAviso({
          mensagem: "Campos obrigatórios não preenchidos",
          tipo: "error"
        });
      }
    },

    inscricaoComAvaliacaoDeDocumentosPendente() {
      let inscricoesNaoAvaliadas = [];

      if (this.processo.possuiRecurso && this.processo.lancamentoSituacao) {
        inscricoesNaoAvaliadas = this.inscricoes.filter(i => this.deveAvaliar(i));
      }

      if (this.processo.possuiRecurso && this.processo.lancamentoParecer) {
        inscricoesNaoAvaliadas = this.inscricoes.filter(i => this.deveAvaliar(i));
      }

      return inscricoesNaoAvaliadas.length > 0;
    },

    podeEditarAvaliacao(situacao) {
      if (['ELM', 'ELMD'].includes(situacao)) {
        return !!this.isAdministrador();
      } else {
        return true;
      }
    },

    handleClickRow(item) {
      if (this.podeEditarAvaliacao(item.situacaoInscricaoCodigo)) {
        this.clickColumn(item);
      }
    }
    ,

    avaliacaoOuParecerPreenchidos(item) {
      if (this.situacoesAvaliacaoDocumentos.includes(item.situacaoInscricaoCodigo)) {
        if (this.processo.lancamentoSituacao) {
          return item.avaliacao && item.avaliacao.replace(/\s*/g, '');
        } else if (this.processo.lancamentoParecer) {
          return item.parecerAvaliador && item.parecerAvaliador.replace(/\s*/g, '');
        }
      }
      return false;
    },

    deveAvaliar(item) {
      if (item.situacaoInscricaoCodigo === 'NAP') {
        if (this.processo.lancamentoSituacao) {
          return !item.avaliacao || !item.avaliacao.replace(/\s*/g, '');
        } else if (this.processo.lancamentoParecer) {
          return !item.parecerAvaliador || !item.parecerAvaliador.replace(/\s*/g, '');
        }
      }
      return false;
    },

    mudarTab() {
      if (this.tab === 0) {
        this.tab = 1;
      } else {
        this.tab = 0;
      }
    },

    verificaAlteracaoInscricoes() {
      const inscricoesAlteradas = [];

      for (let i = 0; i < this.inscricoes.length; i++) {
        if (
            JSON.stringify(this.inscricoes[i]) !==
            JSON.stringify(this.inscricoesIniciais[i])
        ) {
          inscricoesAlteradas.push(this.inscricoes[i]);
        }
      }

      return inscricoesAlteradas;
    },

    verificaAlteracaoInscricoesBonus() {
      const inscricoesAlteradas = [];

      for (let i = 0; i < this.inscricoesBonus.length; i++) {
        if (
            JSON.stringify(this.inscricoesBonus[i]) !==
            JSON.stringify(this.inscricoesIniciaisBonus[i])
        ) {
          inscricoesAlteradas.push(this.inscricoesBonus[i]);
        }
      }

      return inscricoesAlteradas;
    },

    handleOnChange(item) {
      const index = this.expanded.indexOf(item);
      if (index !== -1) {
        if (['ATV', 'APT'].includes(item.situacaoInscricaoCodigo)) {
          this.expanded.splice(index, 1);
        }
      } else {
        if (this.situacoesAvaliacaoDocumentos.includes(item.situacaoInscricaoCodigo)) {
          this.expanded.push(item);
        }
      }
    },

    clickColumn(item) {
      const index = this.expanded.indexOf(item);
      if (index !== -1) {
        if (!this.deveAvaliar(item)) {
          this.expanded.splice(index, 1);
        }
      } else {
        if (this.situacoesAvaliacaoDocumentos.includes(item.situacaoInscricaoCodigo) || item.pediuRecurso) {
          this.expanded.push(item);
        }
      }
    },

    alterarNaoAvaliados() {
      this.inscricoes.filter(i => i.situacaoInscricaoCodigo === 'ATV').forEach(i => i.situacaoInscricaoCodigo = 'NAV');
    },

    async downloadDocumentosParaVisualizacao(inscricao) {
      let loader = this.$loading.show();
      await documentosService
          .downloadDocumentosCandidatoParaVisualizacao(inscricao.inscricaoOid)
          .then(response => {
            this.arquivosVisualizacao = response.data;
            this.dialogDocuments = true;
          })
          .catch(error => {
            let mensagem = "Não foi possível realizar download";
            if (error.response.status === 409) {
              mensagem = error.response.data.msg;
            }
            this.exibirAviso({
              mensagem: mensagem,
              tipo: "error"
            });
          });
      loader.hide();
    },

    filtrarInscricoesLancamentoNota(inscricoes) {
      if (this.processo.lancamentoNota && !this.processo.lancamentoSituacao) {
        if (this.processo.possuiTipoNota) {
          inscricoes.forEach(i => {
            if (i.situacaoInscricaoCodigo === 'APT' && !this.possuiNota(i)) {
              i.situacaoInscricaoCodigo = 'ATV'
            }
          });
        }

        return inscricoes.filter(i => ['APT', 'ATV', 'FALP', 'ELMC', 'NAPN'].includes(i.situacaoInscricaoCodigo))
      } else {
        return inscricoes
      }
    },

    filtrarInscricoesPediuRecurso(inscricoes) {
      if (this.processo.lancamentoParecer) {
        let inscricoesRecurso = [];
        inscricoes.forEach(inscricao => {
          if (inscricao.pediuRecurso) {
            inscricao.tabRecurso = 2;
            inscricoesRecurso.push(inscricao);
          }
        });
        return inscricoesRecurso;
      } else {
        return inscricoes;
      }
    },

    filtrarInscricoesBonus(inscricoes) {
      if (this.processo.possuiBonus) {
        let inscricoesBonus = [];
        inscricoes.forEach(inscricao => {
          if (inscricao.situacaoInscricaoCodigo === 'APT') {
            inscricoesBonus.push(inscricao);
          }
        });
        return inscricoesBonus;
      } else {
        return inscricoes;
      }
    },

    downloadDocumentos(inscricao) {
      documentosService.downloadDocumentosCandidato(inscricao.inscricaoOid).then(response => {
        const file = new Blob([response.data]);
        const fileURL = URL.createObjectURL(file);
        let fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.download = inscricao.cpfCandidato + ".zip";
        fileLink.click();
      }).catch(async error => {
        let mensagem = "Não foi possível realizar download";
        if (error.response.status === 409) {
          await new Promise((resolve) => {
            let reader = new FileReader();
            reader.onload = function () {
              resolve(JSON.parse(reader.result.toString()));
            };
            reader.readAsText(error.response.data);
          }).then(erro => {
            mensagem = erro.msg;
          }).catch();
        }
        this.exibirAviso({
          mensagem: mensagem,
          tipo: "error"
        });
      });
    },
  }
};
</script>

<style scoped>
.v-list-item__title,
.v-list-item__subtitle {
  white-space: unset;
}
</style>

